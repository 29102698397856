import React from 'react'
import './LanDeveloper.css'
import NavBar from '../../components/navbar/NavBar'
import Footer from '../../components/footer/Footer'
import SectionOne from '../../components/section/SectionOne'
import ActionSection from './components/action_section/ActionSection'
import JoinIqlandToday from './components/join_iqland_today/JoinIqlandToday'

function LanDeveloper() {
  return (
    <div className='lan_developer'>
      <NavBar />
      <SectionOne
        sectionOneData={
          {
            title: "IQLAND: Empowering Real Estate Developers with AI-Driven Solutions",
            subtitle: "Transforming Land Development with Precision and Insight",
            paragraph: "At IQLAND, we understand the complexities and challenges that real estate developers face in today's dynamic market. Our platform leverages the power of AI to offer a copilot in your land development journey, enabling you to interact intuitively with parcel data to maximize your project's potential. Discover how we can guide you toward making the most informed decisions for your development projects.",
            image: "/images/lan_home/agent.png",
            alt: "Image of Developer"
          }
        }
      />

      <div className='lan_copilot_featurs lan_container'>
        <div className='iqland_motto'>Interactive Parcel Analysis: Your Questions Answered</div>
        <div className='lan_copilot_featurs_data'>
          <div className='lan_copilot_features_items'>
            <div className='lan_copilot_item'>
              <img src="/images/lan_home/fea_ico_1.png" alt='icon' />
              <div>
                <div className='lan_card__title'>Recommendations for Maximum ROI</div>
                <p className='lan_normal_text'> Ask our AI-powered platform, "What architectural plans will yield the highest return on investment for this parcel?" and receive tailored recommendations based on dynamic KPIs, market trends, and zoning regulations.</p>
              </div>
            </div>
            <div className='lan_copilot_item'>
              <img src="/images/lan_home/fea_ico_2.png" alt='icon' />
              <div>
                <div className='lan_card__title'>Building Potential Unlocked</div>
                <p className='lan_normal_text'>Query, "How big of a home or building can I construct here?" to get precise calculations on allowable building size, including FAR (Floor Area Ratio), setbacks, and maximum height, tailored to local zoning laws.</p>
              </div>
            </div>
            <div className='lan_copilot_item'>
              <img src="/images/lan_home/fea_ico_2.png" alt='icon' />
              <div>
                <div className='lan_card__title'>Local Building Costs Demystified</div>
                <p className='lan_normal_text'>What are the local building costs?" Our system provides up-to-date construction cost estimations, factoring in regional cost variations, materials, and labor, ensuring you have accurate budgeting insights.</p>
              </div>
            </div>
          </div>
          {/* <img className='lan_copilot_featurs_img' src='/images/lan_home/copilot_2.png' alt='copilot' /> */}
        </div>

      </div>

      <div className='lan_home__why_iqland'>
        <div className='iqland_motto' style={{ marginTop: "2rem" }}>Key Performance Indicators (KPIs) at Your Fingertips</div>
        
        <div className='lan_home__why_iqland_cards'>
          <div className='lan_home__why_iqland_card lan_architect__why_iqland_card' style={{ minWidth: "12rem", minHeight: "23rem" }}>
            <img src='/images/lan_home/why_ico_2.png' alt='why' />
            <div className='lan_card__title'>Zoning Compliance</div>
            <p className='lan_normal_text'>Ensure your project aligns with local zoning codes, maximizing land use and compliance.</p>
          </div>
          <div className='lan_home__why_iqland_card lan_architect__why_iqland_card' style={{ minWidth: "12rem", minHeight: "23rem" }}>
            <img src='/images/lan_home/why_ico_3.png' alt='why' />
            <div className='lan_card__title'>Cost Efficiency</div>
            <p className='lan_normal_text'>Access comprehensive cost estimations to optimize your budget and financial planning.</p>
          </div>
          <div className='lan_home__why_iqland_card lan_architect__why_iqland_card' style={{ minWidth: "12rem", minHeight: "23rem" }}>
            <img src='/images/lan_home/why_ico_4.png' alt='why' />
            <div className='lan_card__title'>Profitability Analysis</div>
            <p className='lan_normal_text'>Utilize our AI-driven profitability analysis to evaluate potential returns, incorporating construction costs and market values.</p>
          </div>
          <div className='lan_home__why_iqland_card lan_architect__why_iqland_card' style={{ minWidth: "12rem", minHeight: "23rem" }}>
            <img src='/images/lan_home/why_ico_4.png' alt='why' />
            <div className='lan_card__title'>Value After Completion Metrics</div>
            <p className='lan_normal_text'>Gauge the projected value of your project with critical 'Value After Completion' metrics, aiding strategic decision-making.</p>
          </div>
        </div>
        
      </div>

      <div className='lan_partner_iq'>

        <img className='lan_partner_iq__right' src="/images/lan_home/meeting_1.png" alt="partner" />
        <div className='lan_partner_iq__left'>
          <div className='iqland_motto' style={{ color: "white", marginBottom: "2rem" }}>Streamline Your Development Process</div>
          <div className='lan_partner_items'>
            <div className='lan_partner_item'>
              <img src='/images/lan_home/part_ico_1.png' alt='icon' />
              <div className='lan_partner_item_texts'>
                <div className='lan_card__title' style={{ color: "var(--secondary-clr)" }}>Feasibility Analysis</div>
                <p className='lan_normal_text' style={{ color: "white" }}>Quickly conduct feasibility assessments with AI-enhanced accuracy.</p>
              </div>
            </div>
          </div>
          <div className='lan_partner_items'>
            <div className='lan_partner_item'>
              <img src='/images/lan_home/part_ico_2.png' alt='icon' />
              <div className='lan_partner_item_texts'>
                <div className='lan_card__title' style={{ color: "var(--secondary-clr)" }}>Architectural Plan Access</div>
                <p className='lan_normal_text' style={{ color: "white" }}>Explore a vast library of architectural plans suitable for various land parcels, ensuring you find the perfect match for your project.</p>
              </div>
            </div>
          </div>
          <div className='lan_partner_items'>
            <div className='lan_partner_item'>
              <img src='/images/lan_home/part_ico_2.png' alt='icon' />
              <div className='lan_partner_item_texts'>
                <div className='lan_card__title' style={{ color: "var(--secondary-clr)" }}>Dynamic AI-Driven Dashboard</div>
                <p className='lan_normal_text' style={{ color: "white" }}>Navigate your projects with ease using our interactive, user-friendly dashboard, designed to provide instant insights and facilitate efficient project management.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ActionSection/>
      <JoinIqlandToday/>
      <Footer />
    </div>
  )
}

export default LanDeveloper