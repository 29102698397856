import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react'

function SalesTable({ rows }) {

    function formatCurrency(number) {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(number);
    }

    function formatNumberWithCommas(number) {
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(number);
    }
    const header_style = {
        backgroundColor: "var(--primary-clr)",
        borderRadius: "0.75rem 0.75rem 0 0",
        color: "white",
        fontSize: "0.875rem",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "normal",
        textTransform: "uppercase"
    }

    const body_style = {
        fontSize: "0.875rem",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "normal",
        letterSpacing: "0.00938em",
        textAlign: "left",
        backgroundColor: "#F9F9F9",
        color: "#4C495A"
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow sx={header_style}>
                        <TableCell sx={{ color: "white" }} >Address </TableCell>
                        <TableCell sx={{ color: "white" }} align="center">Distance (miles)</TableCell>
                        <TableCell sx={{ color: "white" }} align="center">Story</TableCell>
                        <TableCell sx={{ color: "white" }} align="center">Living Area (sqft)</TableCell>
                        <TableCell sx={{ color: "white" }} align="center">Bed</TableCell>
                        <TableCell sx={{ color: "white" }} align="center">Bath</TableCell>
                        <TableCell sx={{ color: "white" }} align="center">Yr. Built</TableCell>
                        <TableCell sx={{ color: "white" }} align="center">Lot size (sqft)</TableCell>
                        <TableCell sx={{ color: "white" }} align="center">Price</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow
                            key={index}
                            sx={body_style}
                        >
                            <TableCell sx={{ color: "#4C495A" }} component="th" scope="row">
                                <a href={row.zillow_link} target="_blank" rel="noreferrer">{row.address}</a>
                            </TableCell>
                            <TableCell sx={{ color: "#8E8E8E" }} align="center">{row.distance}</TableCell>
                            <TableCell sx={{ color: "#8E8E8E" }} align="center">{row.story_count}</TableCell>
                            <TableCell sx={{ color: "#8E8E8E" }} align="center">{formatNumberWithCommas(row.area)}</TableCell>
                            <TableCell sx={{ color: "#8E8E8E" }} align="center">{row.bedroom_count}</TableCell>
                            <TableCell sx={{ color: "#8E8E8E" }} align="center">{row.bathroom_count}</TableCell>
                            <TableCell sx={{ color: "#8E8E8E" }} align="center">{row.year}</TableCell>
                            <TableCell sx={{ color: "#8E8E8E" }} align="center">{formatNumberWithCommas(row.lot_area)}</TableCell>
                            <TableCell sx={{ color: "#8E8E8E" }} align="center">{formatCurrency(row.deeds_sale_amount)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default SalesTable