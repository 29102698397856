import { Alert, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, Snackbar, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './NewLogin.css'
import { EmailOutlined, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom'
import { login } from '../../components/auth/AuthService';

function NewLogin() {
    const [toastShow, setToastShow] = useState(false);
    const [toastText, setToastText] = useState('');
    const [toastStatus, setToastStatus] = useState('success');

    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    async function handleSubmit(username, password) {
        try {
            await login(username, password);
            navigate('/verify_login', { state: { email: username } });
        } catch (error) {
            setToastText(error.message);
            setToastStatus("warning");
            setToastShow(true);
        }
    }

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema: Yup.object({
            username: Yup.string().email('Invalid email address').required('Required'),
            password: Yup.string().required('Required'),
        }),
        onSubmit: (values) => {
            handleSubmit(values.username, values.password);
        },
    });

    return (
        <div className='new_login_page'>
            <div className='new_login__image_side'>
                <img src="/images/login/login_page_img.jpg" alt="login" />
            </div>

            <div className='new_login__login_form_area'>
                <form onSubmit={formik.handleSubmit} className='new_login__login_form'>
                    <img className='logo' src='/images/logo.svg' alt='logo' />
                    <div className='new_login__form_title_area'>
                        <Typography className='new_login__form_title'>Login</Typography>
                    </div>
                    <FormControl sx={{ width: "100%" }} variant="outlined" error={formik.touched.username && Boolean(formik.errors.username)}>
                        <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-email"
                            type="email"
                            endAdornment={
                                <InputAdornment position="end">
                                    <EmailOutlined />
                                </InputAdornment>
                            }
                            label="Email"
                            name="username"
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.username && formik.errors.username ? (
                            <FormHelperText>{formik.errors.username}</FormHelperText>
                        ) : null}
                    </FormControl>
                    <FormControl sx={{ width: "100%" }} variant="outlined" error={formik.touched.password && Boolean(formik.errors.password)}>
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={togglePasswordVisibility}
                                    >
                                        {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.password && formik.errors.password ? (
                            <FormHelperText>{formik.errors.password}</FormHelperText>
                        ) : null}
                        <h6 onClick={() => navigate("/forgot_password")} className='new_login__form_forgot_text' sx={{ marginTop: "0.2rem" }}>Forgot Password?</h6>
                    </FormControl>
                    <div className='new_login__buttons'>
                        <div className='new_login__button_area'>
                            <input type="submit" value="Login" className='iq_primary_btn' />
                        </div>
                        <div className='new_login__have_account'>
                            <Typography className='new_login__have_account_text'>Don't have account? <span onClick={() => navigate("/register")}>Sign up</span></Typography>
                        </div>
                        {/* <div className='new_login__or_area'>
                            <img src="/images/login/line_img.svg" alt="login line" />
                            Or
                            <img src="/images/login/line_img.svg" alt="login line" />
                        </div>

                        <div className='social_media_button'>
                            <img src="/images/login/google_icon.svg" alt="login google" />
                            Login with Google
                        </div>
                        <div className='social_media_button'>
                        <img src="/images/login/facebook_icon.svg" alt="login facebook" />
                            Login with Facebook
                        </div> */}
                    </div>
                </form>
            </div>
            <Snackbar
                open={toastShow}
                autoHideDuration={10000}
                onClose={() => setToastShow(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setToastShow(false)}
                    severity={toastStatus}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {toastText}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default NewLogin;
