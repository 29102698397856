import React, { useContext } from 'react'
import './LanCopilot.css'
import NavBar from '../../components/navbar/NavBar'
import Footer from '../../components/footer/Footer'
import ActionSection from './components/action_section/ActionSection'
import JoinIqlandToday from './components/join_iqland_today/JoinIqlandToday'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../components/auth/AuthenticationContext'
import { LoadingSkeletonText } from '../../components/skeleton_loading/SkeletonLoading'

function LanCopilot() {

    const navigate = useNavigate();

    const { user, loading } = useContext(AuthContext);

    return (
        <div className='lan_copilot'>
            <NavBar />
            <div className='lan_copilot_hero'>
                <div className='lan_copilot_hero__left lan_container'>
                    <div className='lan_title' style={{ color: "white", marginBottom: "1rem" }}>IQLAND Copilot: AI-Enhanced Real Estate Development Assistant</div>
                    <p className='lan_normal_text' style={{ color: "white" }}>IQLAND Copilot is an AI assistant for real estate developers, architects, and municipalities. It streamlines land development project evaluation and planning using deep learning and an extensive database. Copilot provides precise, data-driven insights on construction costs and potential ROI, simplifying complex project assessments.</p>
                </div>
                <div className='lan_copilot_hero__right'>
                    <img src='/images/lan_home/copilot_2.png' alt='copilot' />
                </div>
            </div>

            <div className='lan_copilot_featurs lan_container'>
                <div className='iqland_motto'>Key Features</div>
                <div className='lan_copilot_featurs_data'>
                    <div className='lan_copilot_features_items'>
                        <div className='lan_copilot_item'>
                            <img src="/images/lan_home/fea_ico_1.png" alt='icon' />
                            <div>
                                <div className='lan_card__title'>Ask average building cost </div>
                                <p className='lan_normal_text'>Query Copilot for average building costs in any city to budget accurately.</p>
                            </div>
                        </div>
                        <div className='lan_copilot_item'>
                            <img src="/images/lan_home/fea_ico_2.png" alt='icon' />
                            <div>
                                <div className='lan_card__title'>ROI Plan Recommendations </div>
                                <p className='lan_normal_text'>Use Copilot to recommend architectural plans for the best ROI</p>
                            </div>
                        </div>
                        <div className='lan_copilot_item'>
                            <img src="/images/lan_home/fea_ico_2.png" alt='icon' />
                            <div>
                                <div className='lan_card__title'>Check Land Use Entitlements</div>
                                <p className='lan_normal_text'>Clarify parcel entitlements, regulations, and streamline the pre-development.</p>
                            </div>
                        </div>
                    </div>
                    <img className='lan_copilot_featurs_img' src='/images/lan_home/copilot_3.png' alt='copilot' />
                </div>

            </div>

            <div className='lan_copilot_key_features lan_container'>
                <img lassName='lan_copilot_key_featurs_img' src='/images/lan_home/key_feature_1.png' alt='key_features' />
                <div className='lan_copilot_key_features_right'>
                    <div className='lan_title' style={{ color: "var(--primary-clr)" }}>Benefits</div>
                    <div className='lan_gold_subtitle'>Get Personalized Guidance, Instantly</div>
                    <p className='lan_normal_text'>Ask customized questions and receive expert guidance, complete with sources and data to inform your decisions.</p>
                    {
                        !loading ?
                            <div
                                className='lan_primary_button'
                                onClick={() => user ? navigate("/copilot") : navigate("/register")}>
                                Get Started
                            </div>
                            :
                            <LoadingSkeletonText width={"10rem"} height={"2.5rem"} />
                    }
                </div>
            </div>

            <div className='lan_copilot_exp'>
                <div className='lan_copilot_exp_texts'>
                    <div className='lan_title' style={{ color: "white" }}>Experience Unmatched Efficiency and Insight</div>
                    <p className='lan_normal_text' style={{ color: "white" }}>IQLAND Copilot is more than a tool; it’s a partner in your development journey, designed to enhance decision-making and project planning through AI-driven insights. With Copilot, you unlock a world where every decision is informed, every potential is explored, and every project moves closer to success with confidence.</p>
                </div>
                <img src='/images/lan_home/hand_shake_2.png' alt='hands' />
            </div>

            <ActionSection />
            <JoinIqlandToday />
            <Footer />
        </div>
    )
}

export default LanCopilot