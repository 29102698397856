import React from 'react'
import './ChatSource.css'

function ChatSource({ sources }) {
    return (
        <div className='chat_source'>
            <div>Sources: </div>
            <div className='sources'>
                {sources.map((source, index) => (
                    <a href={source.source} target='_blank' rel="noreferrer" className='source' style={{fontSize:"0.5rem"}} key={index}>{source.name}</a>
                ))}
            </div>
        </div>
    )
}

export default ChatSource