import React, { useState } from 'react'
import "./MissingInput.css"
import GoogleAddressInput from '../../../components/google_map/GoogleAddressInput'
import { Accordion, AccordionDetails, AccordionSummary, FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import MDropDown from '../../dashboard_new/component/MDropDown';
import { ExpandMoreOutlined } from '@mui/icons-material';

export function AddressOnly({ preMsg, handleSubmit, setFullChat }) {
    const [address, setAddress] = useState("");
    const handleSubmitClick = () => {
        setFullChat(prevChats => prevChats.slice(0, -1));
        handleSubmit(`${preMsg} Address: ${address}`)
    }
    return (
        <div className='missing_input_container'>
            <GoogleAddressInput address={address} setAddress={setAddress} handleKeyPress={() => { }} />
            <button className='missing_input_button' onClick={() => handleSubmitClick()}>Submit</button>
        </div>
    )
}

export function CostEstimation({ preMsg, handleSubmit, setFullChat, preAddress, missingInputs }) {
    const formik = useFormik({
        initialValues: {
            address: preAddress !== "NA" ? preAddress : "",
            building_area: "15000",
            build_quality: "BRONZE",
            story_count: 1,
            garage_area: 0,
            basement_area: 0,
            basement_quality: "No",
            bathrooms_full_n: 1,
            bathrooms_half_n: 1,
            kitchen_n: 1,
            wall_type: "Brick Veneer"
        },
        validationSchema: Yup.object({
            address: Yup.string().required('Address is required'),
            building_area: Yup.number()
                .typeError('Building Area must be a number')
                .required('Building Area is required'),
            basement_area: Yup.number()
                .typeError('Basement Area must be a number'),
            garage_area: Yup.number()
                .typeError('Garage Area must be a number')
        }),
        onSubmit: (values) => {
            handleSubmitClick(values)
        }

    });
    // const [address, setAddress] = useState("");
    const handleSubmitClick = (data) => {
        setFullChat(prevChats => prevChats.slice(0, -1));
        // handleSubmit(`${preMsg} Address: ${formik.values.address} Building Area: ${formik.values.building_area} Build Quality: ${formik.values.build_quality} Story Count: ${formik.values.story_count} Garage Area: ${formik.values.garage_area} Basement Area: ${formik.values.basement_area} Basement Quality: ${formik.values.basement_quality}`)
        handleSubmit(`${preMsg}
            ${missingInputs.includes("address") ? `Address: ${data.address}` : ""}
            ${missingInputs.includes("building_area") ? `Building Area: ${data.building_area} sqft` : ""}
            ${missingInputs.includes("building_quality") ? `Build Quality: ${data.build_quality}` : ""}
            Story Count: ${data.story_count}
            Garage Area: ${data.garage_area} sqft
            Basement Area: ${data.basement_area}
            Basement Quality: ${data.basement_quality}
            Bathrooms: ${data.bathrooms_full_n}
            Kitchen: ${data.kitchen_n}
            Wall Type: ${data.wall_type}`

        )
    }
    return (
        <div className='missing_input_container'>
            <h6>Required</h6>
            {missingInputs.includes("address") && <GoogleAddressInput address={formik.values.address} setAddress={(value) => { formik.setFieldValue("address", value) }} handleKeyPress={() => { }} />}
            {missingInputs.includes("building_area") && <FormControl sx={{ width: "100%" }} variant="outlined" error={formik.touched.building_area && Boolean(formik.errors.building_area)}>
                <InputLabel htmlFor="outlined-adornment-first">Building Area(sqft)</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-first"
                    type="text"
                    label="Building Area(sqft)"
                    name="building_area"
                    value={formik.values.building_area}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                {formik.touched.building_area && formik.errors.building_area ? (
                    <FormHelperText>{formik.errors.building_area}</FormHelperText>
                ) : null}
            </FormControl>}
            {
                missingInputs.includes("building_quality") && <MDropDown
                    label={"Build Quality"}
                    values={["BRONZE", "SILVER", "GOLD"]}
                    texts={["Economy", "Standard", "Premium"]}
                    value={formik.values.build_quality}
                    setValue={(value) => {
                        formik.setFieldValue("build_quality", value);
                    }}
                />
            }
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{ minHeight: "2rem" }}
                >
                    <div>
                        <h6 style={{ marginBottom: "0px" }}>Additional</h6>
                    </div>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem"
                    }}
                >
                    <MDropDown
                        label={"Wall Type"}
                        values={["Brick Veneer", "Wood Frame"]}
                        texts={["Brick Veneer", "Wood Frame"]}
                        value={formik.values.wall_type}
                        setValue={(value) => {
                            formik.setFieldValue("wall_type", value);
                        }}
                    />


                    <MDropDown
                        label={"Basement Quality"}
                        values={["No", "Unfinished", "Finished"]}
                        texts={["No", "Unfinished", "Finished"]}
                        value={formik.values.basement_quality}
                        setValue={(value) => {
                            formik.setFieldValue("basement_quality", value);
                        }}
                    />

                    <div style={{ display: "flex", gap: "2rem" }}>

                        <FormControl sx={{ flex: "1" }} variant="outlined" error={formik.touched.basement_area && Boolean(formik.errors.basement_area)}>
                            <InputLabel htmlFor="outlined-adornment-first">Basement Area(sqft)</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-first"
                                type="text"
                                label="Basement Area(sqft)"
                                name="basement_area"
                                sx={{ height: "2.5rem" }}
                                value={formik.values.basement_area}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.basement_area && formik.errors.basement_area ? (
                                <FormHelperText>{formik.errors.basement_area}</FormHelperText>
                            ) : null}
                        </FormControl>

                        <FormControl sx={{ flex: "1" }} variant="outlined" error={formik.touched.garage_area && Boolean(formik.errors.garage_area)}>
                            <InputLabel htmlFor="outlined-adornment-first">Garage Area(sqft)</InputLabel>
                            <OutlinedInput
                                sx={{ height: "2.5rem" }}
                                id="outlined-adornment-first"
                                type="text"
                                label="Garage Area(sqft)"
                                name="garage_area"
                                value={formik.values.garage_area}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.garage_area && formik.errors.garage_area ? (
                                <FormHelperText>{formik.errors.garage_area}</FormHelperText>
                            ) : null}
                        </FormControl>
                    </div>
                    <div style={{ display: "flex", gap: "2rem" }}>
                        <MDropDown
                            label={"Story Count"}
                            values={["1", "2", "3"]}
                            texts={["1", "2", "3"]}
                            value={formik.values.story_count}
                            setValue={(value) => {
                                formik.setFieldValue("story_count", value);
                            }}
                        />
                        <MDropDown
                            label={"Bathrooms"}
                            values={["1", "2", "3", "4", "5", "6", "7", "8"]}
                            texts={["1", "2", "3", "4", "5", "6", "7", "8"]}
                            value={formik.values.bathrooms_full_n}
                            setValue={(value) => {
                                formik.setFieldValue("bathrooms_full_n", value);
                            }}
                        />
                        <MDropDown
                            label={"Kitchen"}
                            values={["1", "2", "3"]}
                            texts={["1", "2", "3"]}
                            value={formik.values.kitchen_n}
                            setValue={(value) => {
                                formik.setFieldValue("kitchen_n", value);
                            }}
                        />
                    </div>


                </AccordionDetails>
            </Accordion>

            <button className='missing_input_button' onClick={() => formik.handleSubmit()}>Submit</button>
        </div>
    )
}

export function PlanRecommend({ preMsg, handleSubmit, setFullChat, missingInputs, preAddress }) {
    const formik = useFormik({
        initialValues: {
            address: preAddress !== "NA" ? preAddress : "",
            min_total_area: "0",
            max_total_area: "15000",
            story_count: 1,
        },
        validationSchema: Yup.object({
            address: Yup.string().required('Address is required'),
            min_total_area: Yup.number()
                .typeError('Min total area must be a number')
                .required('Min total area is required'),
            max_total_area: Yup.number()
                .typeError('Max total area must be a number')
                .required('Max total area is required'),
        }),
        onSubmit: (values) => {
            handleSubmitClick(values)
        }

    });

    const handleSubmitClick = (data) => {
        setFullChat(prevChats => prevChats.slice(0, -1));
        // handleSubmit(`${preMsg} Address: ${formik.values.address} Building Area: ${formik.values.building_area} Build Quality: ${formik.values.build_quality} Story Count: ${formik.values.story_count} Garage Area: ${formik.values.garage_area} Basement Area: ${formik.values.basement_area} Basement Quality: ${formik.values.basement_quality}`)
        handleSubmit(`${preMsg}
            ${missingInputs.includes("address") ? `Address: ${data.address}` : ""}
            ${missingInputs.includes("min_total_area") ? `Min total area: ${data.min_total_area} sqft` : ""}
            ${missingInputs.includes("max_total_area") ? `Max total area: ${data.max_total_area} sqft` : ""}
            ${missingInputs.includes("stories") ? `Story Count: ${data.story_count}` : ""}`

        )
    }


    return (
        <div className='missing_input_container'>
            <h6>Required</h6>
            {missingInputs.includes("address") && <GoogleAddressInput address={formik.values.address} setAddress={(value) => { formik.setFieldValue("address", value) }} handleKeyPress={() => { }} />}
            {missingInputs.includes("min_total_area") && <FormControl sx={{ width: "100%" }} variant="outlined" error={formik.touched.min_total_area && Boolean(formik.errors.min_total_area)}>
                <InputLabel htmlFor="outlined-adornment-first">Min Total Area(sqft)</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-first"
                    type="text"
                    label="Min Total Area(sqft)"
                    name="min_total_area"
                    value={formik.values.min_total_area}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                {formik.touched.min_total_area && formik.errors.min_total_area ? (
                    <FormHelperText>{formik.errors.min_total_area}</FormHelperText>
                ) : null}
            </FormControl>}
            {missingInputs.includes("max_total_area") && <FormControl sx={{ width: "100%" }} variant="outlined" error={formik.touched.max_total_area && Boolean(formik.errors.max_total_area)}>
                <InputLabel htmlFor="outlined-adornment-first">Max Total Area(sqft)</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-first"
                    type="text"
                    label="Max Total Area(sqft)"
                    name="max_total_area"
                    value={formik.values.max_total_area}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                {formik.touched.max_total_area && formik.errors.max_total_area ? (
                    <FormHelperText>{formik.errors.max_total_area}</FormHelperText>
                ) : null}
            </FormControl>}

            {
                missingInputs.includes("stories") && <MDropDown
                    label={"Story Count"}
                    values={["1", "2", "3"]}
                    texts={["1", "2", "3"]}
                    value={formik.values.story_count}
                    setValue={(value) => {
                        formik.setFieldValue("story_count", value);
                    }}
                />
            }
            

            {/* <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{ minHeight: "2rem" }}
                >
                    <div>
                        <h6 style={{ marginBottom: "0px" }}>Additional</h6>
                    </div>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem"
                    }}
                >
                    <MDropDown
                        label={"Wall Type"}
                        values={["Brick Veneer", "Wood Frame"]}
                        texts={["Brick Veneer", "Wood Frame"]}
                        value={formik.values.wall_type}
                        setValue={(value) => {
                            formik.setFieldValue("wall_type", value);
                        }}
                    />


                    <MDropDown
                        label={"Basement Quality"}
                        values={["No", "Unfinished", "Finished"]}
                        texts={["No", "Unfinished", "Finished"]}
                        value={formik.values.basement_quality}
                        setValue={(value) => {
                            formik.setFieldValue("basement_quality", value);
                        }}
                    />

                    <div style={{ display: "flex", gap: "2rem" }}>

                        <FormControl sx={{ flex: "1" }} variant="outlined" error={formik.touched.basement_area && Boolean(formik.errors.basement_area)}>
                            <InputLabel htmlFor="outlined-adornment-first">Basement Area(sqft)</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-first"
                                type="text"
                                label="Basement Area(sqft)"
                                name="basement_area"
                                sx={{ height: "2.5rem" }}
                                value={formik.values.basement_area}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.basement_area && formik.errors.basement_area ? (
                                <FormHelperText>{formik.errors.basement_area}</FormHelperText>
                            ) : null}
                        </FormControl>

                        <FormControl sx={{ flex: "1" }} variant="outlined" error={formik.touched.garage_area && Boolean(formik.errors.garage_area)}>
                            <InputLabel htmlFor="outlined-adornment-first">Garage Area(sqft)</InputLabel>
                            <OutlinedInput
                                sx={{ height: "2.5rem" }}
                                id="outlined-adornment-first"
                                type="text"
                                label="Garage Area(sqft)"
                                name="garage_area"
                                value={formik.values.garage_area}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.garage_area && formik.errors.garage_area ? (
                                <FormHelperText>{formik.errors.garage_area}</FormHelperText>
                            ) : null}
                        </FormControl>
                    </div>
                    <div style={{ display: "flex", gap: "2rem" }}>
                        <MDropDown
                            label={"Story Count"}
                            values={["1", "2", "3"]}
                            texts={["1", "2", "3"]}
                            value={formik.values.story_count}
                            setValue={(value) => {
                                formik.setFieldValue("story_count", value);
                            }}
                        />
                        <MDropDown
                            label={"Bathrooms"}
                            values={["1", "2", "3", "4", "5", "6", "7", "8"]}
                            texts={["1", "2", "3", "4", "5", "6", "7", "8"]}
                            value={formik.values.bathrooms_full_n}
                            setValue={(value) => {
                                formik.setFieldValue("bathrooms_full_n", value);
                            }}
                        />
                        <MDropDown
                            label={"Kitchen"}
                            values={["1", "2", "3"]}
                            texts={["1", "2", "3"]}
                            value={formik.values.kitchen_n}
                            setValue={(value) => {
                                formik.setFieldValue("kitchen_n", value);
                            }}
                        />
                    </div>


                </AccordionDetails>
            </Accordion> */}

            <button className='missing_input_button' onClick={() => formik.handleSubmit()}>Submit</button>
        </div>
    )
}
