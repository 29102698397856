import React from 'react'
import "./NewChat.css"
import { preMessage } from '../data/PreMessage'

function NewChat({ setInput, selectedPrompt }) {
    return (
        <div className='copilot_new_chat'>
            <div className='copilot_new_chat__logo_area'>
                <img src="/images/logo.svg" alt='iqland' />
                <h3>Let's get started</h3>
            </div>
            <div className='copilot_new_chat__cards'>

                {
                    preMessage.map((item, index) => (
                        <div
                            className='copilot_new_chat__card'
                            style={selectedPrompt === item.content ? { backgroundColor: "#dadada" } : {}}
                            key={index}
                            onClick={() => setInput(item.content)}>
                            <h6>{item.title}</h6>
                            <div>{item.content}</div>
                        </div>
                    ))
                }


                {/* <div className='copilot_new_chat__card'>
                <h6>Find a home for me to build</h6>
                <div>Explore new construction home plans that qualify for your parcel. Modern, Craftsman, </div>
            </div>
            <div className='copilot_new_chat__card'>
                <h6>Find a home for me to build</h6>
                <div>Explore new construction home plans that qualify for your parcel. Modern, Craftsman, </div>
            </div>
            <div className='copilot_new_chat__card'>
                <h6>Find a home for me to build</h6>
                <div>Explore new construction home plans that qualify for your parcel. Modern, Craftsman, </div>
            </div>
            <div className='copilot_new_chat__card'>
                <h6>Find a home for me to build</h6>
                <div>Explore new construction home plans that qualify for your parcel. Modern, Craftsman, </div>
            </div>
            <div className='copilot_new_chat__card'>
                <h6>Find a home for me to build</h6>
                <div>Explore new construction home plans that qualify for your parcel. Modern, Craftsman, </div>
            </div>
            <div className='copilot_new_chat__card'>
                <h6>Find a home for me to build</h6>
                <div>Explore new construction home plans that qualify for your parcel. Modern, Craftsman, </div>
            </div> */}

            </div>
        </div>
    )
}

export default NewChat