import React, { useState } from "react";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { getCsrfToken } from "../../components/user_service/UserService";
import "./UpdatePaymentMethod.css";
import { Alert, Snackbar } from "@mui/material";

const API_URL = process.env.REACT_APP_BACK_END_END_POINT;
const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const [toastShow, setToastShow] = useState(false);
  const [toastText, setToastText] = useState('Updated');
  const [toastStatus, setToastStatus] = useState('success');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setMessage("");
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);




    // Create a payment method
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setMessage(error.message);
      setLoading(false);
      return;
    }

    // Send payment method to your backend to attach to the subscription
    const csrfToken = await getCsrfToken();
    axios.defaults.headers.post["X-CSRFToken"] = csrfToken;
    axios.defaults.withCredentials = true;
    try {
      const response = await axios.post(
        `${API_URL}/subscription/update-payment-method/`,
        {
          payment_method_id: paymentMethod.id,
        }
      );
      setToastText(response.data.message);
      setToastStatus("success");
      setToastShow(true);
      // console.log(response);
    }
    catch (error) {
      setToastText("Your card number is invalid.");
      setToastStatus("error");
      setToastShow(true);
      // console.log(error);
    }

    // const responseData = await response.json();
    // setMessage(responseData.message);
    setLoading(false);
  };

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"poppins", sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="billing_setting__part update_payment_method">
        <div className="iq_textbox_label" style={{ marginBottom: "1rem" }}>
          <label htmlFor="card-element" className="card-element-label">
            Update Card
          </label>
        </div>
        <CardElement id="card-element" options={{ ...cardStyle, hidePostalCode: true }} />
        <button type="submit" disabled={!stripe || loading} style={{ marginTop: "1rem" }}>
          {loading ? "Updating..." : "Update"}
        </button>
        {message && <div style={{ color: "red" }}>{message}</div>}
      </form>

      <Snackbar
        open={toastShow}
        autoHideDuration={1500}
        onClose={() => setToastShow(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          severity={toastStatus}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {toastText}
        </Alert>
      </Snackbar>
    </div>
  );
};

const UpdatePaymentMethod = ({ stripePromise }) => (
  <Elements stripe={stripePromise}>
    <PaymentForm />
  </Elements>
);

export default UpdatePaymentMethod;
