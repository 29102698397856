export const newStyles = [
    {
        "title": "Bungalow and Craftsman",
        "styles": [
            "Bungalow",
            "Craftsman"
        ]
    },
    {
        "title": "Coastal and Beach",
        "styles": [
            "Coastal",
            "Beach",
            "Coastal Contemporary"
        ]
    },
    {
        "title": "Luxury and Grand",
        "styles": [
            "French Country",
            "Acadian"
        ]
    },
    {
        "title": "Mediterranean and Southwestern",
        "styles": [
            "Mediterranean",
            "Spanish",
            "Southwest",
            "Adobe",
            "Pueblo",
            "Tuscan",
            "Florida"
        ]
    },
    {
        "title": "Mountain and Hill Country",
        "styles": [
            "Mountain",
            "Hill Country"
        ]
    },
    {
        "title": "Modern and Contemporary",
        "styles": [
            "Modern Farmhouse",
            "New American",
            "Scandinavian",
            "Transitional",
            "Contemporary",
            "Modern"
        ]
    },
    {
        "title": "Prairie and Mid-Century Modern",
        "styles": [
            "Prairie",
            "Mid-Century Modern"
        ]
    },
    {
        "title": "Rustic and Farmhouse",
        "styles": [
            "Rustic",
            "Log",
            "Cabin",
            "Farmhouse",
            "Cottage",
            "Barndominium",
            "Ranch"
        ]
    },
    {
        "title": "Southern Styles",
        "styles": [
            "Southern",
            "Southern Traditional",
            "Low Country"
        ]
    },
    {
        "title": "Traditional and Classic",
        "styles": [
            "Colonial",
            "Georgian",
            "Traditional",
            "European",
            "Neoclassical",
            "Cape Cod"
        ]
    },
    {
        "title": "Urban and Townhouse",
        "styles": [
            "Townhouse",
            "Row House",
            "Carriage",
            "A-Frame"
        ]
    },
    {
        "title": "Victorian and Historic",
        "styles": [
            "Victorian",
            "Tudor",
            "Queen Anne"
        ]
    },

]
