import React from 'react'
import './SectionOne.css'

function SectionOne({ sectionOneData, right }) {

    const changeDirection = right ? 'section_one direction' : 'section_one';


    return (
        <section className='section-top'>
            <div className={changeDirection}>
                <div className='texts'>
                    <h1 className='title'>{sectionOneData.title}</h1>
                    <h4 className='subtitle'>{sectionOneData.subtitle}</h4>
                    <p className='paragraph'>{sectionOneData.paragraph}</p>
                </div>
                <div className='image'>
                    <img src={sectionOneData.image} alt={sectionOneData.alt} />
                </div>
            </div>
        </section>
    )
}

export default SectionOne