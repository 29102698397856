import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { getSubscriptionPlans } from "../../components/auth/AuthService";
import CheckoutForm from "./CheckoutForm";
import NavBar from "../../components/navbar/NavBar";
import { DoneOutlined } from "@mui/icons-material";
import "./Payment.css";
import PaymentSchedule from "./PaymentSchedule";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import IQSpinner from "../../components/util_component/iq_spinner/IQSpinner";

function Payment(props) {

  // convert to currency
  function formatCurrency(number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(number);
  }

  const [paymentLoading, setPaymentLoading] = useState(false);

  const [agree, setAgree] = useState(false);

  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const checkoutButtonRef = useRef(null);
  const query = new URLSearchParams(location.search);
  const priceId = query.get("id");
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [selectedPlanPrice, setSelectedPlanPrice] = useState();

  const { stripePromise } = props;

  const [showModal, setShowModal] = useState(false);

  const handleModal = () => {
    setShowModal(!showModal);
  };

  const options = {
    mode: "subscription",
    amount: 0,
    currency: "usd",
  };

  const standardPlanFeatures = [
    "Search residential parcels",
    "Parcel intelligent copilot",
    "Pro-forma and feasibility reports",
    "Architectural plan database",
    "Parcel and Zoning Information",
    "Comparable sales list",
    "Download and share reports",
    "Lot subdivision (Coming soon)",
    "Pre-permit plan validation",
  ];


  async function getPlans() {
    try {
      const response = await getSubscriptionPlans();
      // console.log(response);
      const plans = response.products.find((item) =>
        item.prices.some((price) => price.id === priceId)
      );
      setSubscriptionPlans([plans]);
      setSelectedPlanPrice(plans.prices.find((item) => item.id === priceId));
      // console.log(plans);
    } catch (error) {
      // console.log(error);
    }
  }

  useEffect(() => {
    getPlans();
  }, []);

  const handleClick = () => {
    setPaymentLoading(true);
    try {
      if (checkoutButtonRef.current) {
        checkoutButtonRef.current.click();
      }
      else {
        setPaymentLoading(false);
      }
    }
    catch (err) {
      // console.log(err);
      setPaymentLoading(false);
    }
  };

  const calculatedDiscountedAmount = () => {
    if (selectedPlanPrice) {
      return (
        parseFloat(selectedPlanPrice?.unit_amount) -
        (parseFloat(selectedPlanPrice?.unit_amount) *
          parseInt(selectedPlanPrice?.discount_percentage?.replace("%", ""))) /
        100
      ).toFixed(2);
    } else {
      return "";
    }
  };

  return (
    <>
      <NavBar handleVisiblity={handleModal} />
      <div className="checkout_page">
        <div className="checkout_page__top">
          <div className="checkout_page__plan">
            <div className="checkout_page__price">{formatCurrency(selectedPlanPrice?.unit_amount)}<span>/{selectedPlanPrice?.interval}</span></div>
            <div className="checkout_page__plan_title">{subscriptionPlans[0]?.name} Plan</div>
            <div className="checkout_page__contents">
              {standardPlanFeatures?.map((feature, index) => (
                <div
                  key={`feature-${index}`}
                  className="checkout_page__contents_item"
                >
                  <DoneOutlined
                    sx={{
                      fontSize: "14px",
                      color: "#126342",
                    }}
                  />
                  <div className="checkout_page__contents_text">{feature}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="checkout_page__summary">
            <div className="checkout_page__summary_top">
              <div className="checkout_page__summary_title">Summary</div>
              <div className="checkout_page__summary_items">
                <div className="checkout_page__summary_item">
                  <div className="checkout_page__summary_item_text">{subscriptionPlans[0]?.name} Plan</div>
                  <div className="checkout_page__summary_item_price">{formatCurrency(selectedPlanPrice?.unit_amount)}</div>
                </div>
                <div className="checkout_page__summary_item">
                  <div className="checkout_page__summary_item_text">{selectedPlanPrice?.discount_percentage} Discount</div>
                  <div className="checkout_page__summary_item_price">
                    {selectedPlanPrice && formatCurrency(selectedPlanPrice?.unit_amount * parseFloat(selectedPlanPrice?.discount_percentage) / 100)
                      // (
                      //   (parseFloat(selectedPlanPrice?.unit_amount) *
                      //     parseInt(
                      //       selectedPlanPrice?.discount_percentage?.replace(
                      //         "%",
                      //         ""
                      //       )
                      //     )) /
                      //   100
                      // )
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="checkout_page__summary_bottom">
              <div className="checkout_page__summary_item">
                <div className="checkout_page__summary_total">Total</div>
                <div className="checkout_page__summary_total_price">{formatCurrency(calculatedDiscountedAmount())}</div>
              </div>
              <hr style={{ background: "#CCC" }} />
              <FormGroup>
                <FormControlLabel
                  control={
                    <div className='checkout_page__summary_terms_area'>
                      <Checkbox
                        checked={agree}
                        onChange={() => setAgree(!agree)}
                        name='walltype_select_all'
                        sx={{
                          '&.Mui-checked': { color: "var(--primary-clr)" },
                          '& .MuiSvgIcon-root': { fontSize: 24 }
                        }}
                      />
                      <div className="checkout_page__summary_terms_text">I have read and agree terms of services</div>
                    </div>
                  }
                />
              </FormGroup>
              <div
                className={agree ? "lan_primary_button" : "lan_disabled_button"}
                style={{ width: "100%", marginTop: "3.5rem" }}
                onClick={agree ? handleClick : () => { }}
              >
                {!paymentLoading ? "Checkout" : <IQSpinner />}
              </div>
            </div>
          </div>
        </div>
        <PaymentSchedule
          subscriptionPlans={subscriptionPlans}
          setSelectedPlanPrice={setSelectedPlanPrice}
          selectedPriceIdDefault={selectedPlanPrice?.id ?? priceId}
        />
        <div className="checkout_page__stripe_area">
          <div className="checkout_page__stripe_title">Enter Payment Details</div>
          <div className="checkout_page__stripe_card">
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm
                checkoutButtonRef={checkoutButtonRef}
                priceId={priceId}
                loading={loading}
                setLoading={setLoading}
              />
            </Elements>
          </div>
        </div>
      </div>
      {/* <div className="checkout_plan">
        <div className="checkout_plan__details">
          <div className="checkout_plan__content">
            <div className="checkout_plan__price">
              <div className="checkout_plan__price_heading">
                <div className="checkout_plan__price_heading__amount">
                  ${selectedPlanPrice?.unit_amount}
                </div>
                <div className="checkout_plan__price_heading__duration">
                  /{selectedPlanPrice?.interval}
                </div>
              </div>
              <div className="checkout_plan__price_detail"></div>
            </div>
            <div className="checkout_plan__info">
              <div className="checkout_plan__info__name">Premium Plan</div>
              <div className="checkout_plan__info__description"></div>
            </div>
            <div className="checkout_plan__features">
              {[1, 2, 3, 4, 5, 6, 7, 8]?.map((feature, featureIndex) => (
                <div
                  key={`feature-${featureIndex}`}
                  className="checkout_plan__feature"
                >
                  <div className="checkout_plan__feature__icon">
                    <DoneOutlined
                      sx={{
                        fontSize: "14px",
                        color: "#126342",
                      }}
                    />
                  </div>
                  <div className="checkout_plan__feature__text">
                    <b>Search</b> for Parcel by Address
                  </div>
                </div>
              ))}
            </div>
          </div>
          <PaymentSchedule
            subscriptionPlans={subscriptionPlans}
            setSelectedPlanPrice={setSelectedPlanPrice}
            selectedPriceIdDefault={selectedPlanPrice?.id ?? priceId}
          />
        </div>
      </div> */}
      {/* <div className="checkout_plan__summary">
        <div className="checkout_summary__title">Summary</div>
        <div className="checkout_summary__content">
          <div className="checkout_summary__nested">
            <div className="checkout_summary_items">
              <div className="checkout_summary__item">
                <div className="checkout_summary__item">Premium Plan</div>
                <div className="checkout_summary__price">
                  ${selectedPlanPrice?.unit_amount}
                </div>
              </div>
              <div className="checkout_summary__item">
                <div className="checkout_summary__title">
                  {selectedPlanPrice?.discount_percentage} Discount
                </div>
                <div className="checkout_summary__price">
                  $
                  {selectedPlanPrice != null &&
                    (
                      (parseFloat(selectedPlanPrice?.unit_amount) *
                        parseInt(
                          selectedPlanPrice?.discount_percentage?.replace(
                            "%",
                            ""
                          )
                        )) /
                      100
                    ).toFixed(2)}
                </div>
              </div>
            </div>
            <div className="checkout_summary__total_container">
              <div className="checkout_summary__item">
                <div className="checkout_summary__total">Total</div>
                <div className="checkout_summary__price">
                  {calculatedDiscountedAmount()}
                </div>
              </div>
              <div className="checkout_summary__confirm">
                <div>Checkbox Input</div>
                <div className="checkout_summary__agree">
                  I have read and agree terms of services
                </div>
              </div>
            </div>
          </div>
          <button
            className="checkout_summary__link"
            onClick={handleClick}
            disabled={loading}
          >
            Buy Now
          </button>
        </div>
      </div> */}
    </>
  );
}

export default Payment;
