import React, { useState } from 'react'
import NavBar from '../../components/navbar/NavBar'
import Footer from '../../components/footer/Footer'
import axios from 'axios';
import './ContactUs.css'
import { Alert, Snackbar } from '@mui/material';

function ContactUs() {

    const [toastShow, setToastShow] = useState(false);
    const [toastText, setToastText] = useState('Updated');
    const [toastStatus, setToastStatus] = useState('success');


    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [occupation, setOccupation] = useState('');
    const [comments, setComments] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.post(`${process.env.REACT_APP_BACK_END_END_POINT}/waitlist/`, { email: email, first_name: firstName, last_name: lastName, occupation: occupation, comments: comments});
            setToastText('Message Sent');
            setToastStatus('success');
            setToastShow(true);
        } catch (error) {
            setToastText('Error submitting email');
            setToastStatus('error');
            setToastShow(true);
            console.error('Error submitting email:', error);
        }
        // console.log(message);
    };
    return (
        <div className='contactus_page'>
            <NavBar />
            <div className='contactus__container'>
                <div className='contactus_left-area'>
                    <img src="/images/lan_home/contact_us_page.jpeg" alt='contact'/>
                </div>
                <div className='contactus_right-area'>
                    <h1>Contact us</h1>
                    <p>At IQLAND, your feedback is important to us. Whether you have product questions, need support, or want to share suggestions, our team is here to help. Please complete the form below and a representative will respond with you shortly</p>
                    <form onSubmit={handleSubmit}>
                        <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder='First Name' required />
                        <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder='Last Name' required />
                        <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email' required />
                        <input type="text" value={occupation} onChange={(e) => setOccupation(e.target.value)} placeholder='Occupation' required />
                        <textarea value={comments} onChange={(e) => setComments(e.target.value)} placeholder='Comments' required />
                        <button type='submit'>Send</button>
                    </form>
                </div>
            </div>
            <Snackbar
                open={toastShow}
                autoHideDuration={1500}
                onClose={() => setToastShow(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    severity={toastStatus}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {toastText}
                </Alert>
            </Snackbar>
            <Footer />
        </div>
    )
}

export default ContactUs