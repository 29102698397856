import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { getReport } from '../../components/user_service/UserService';
import { LoadingSkeleton } from '../../components/skeleton_loading/SkeletonLoading';
import './ReportPage.css'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { KeyboardArrowLeftOutlined } from '@mui/icons-material';

function ReportPage({ tab }) {

    // convert to currency
    function formatCurrency(number) {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(number);
    }

    function roundToTwo(num) {
        if (num && num !== "-") {
            return Math.round(num * 100) / 100;
        }
        return "-";
    }

    const navigate = useNavigate();

    const [report, setReport] = useState();

    useEffect(() => {

        getReport(tab)
            .then((res) => {
                setReport(res.data);
                // console.log(res.data);
            })
            .catch((err) => {
                // console.log(err);
                navigate('/not_found');
            })
    }, [tab]);

    return (
        <div className='report_page'>
            <div className='report_page__back' onClick={() => navigate("/reports")}><KeyboardArrowLeftOutlined/> Back</div>
            {
                report ?
                    <TableContainer component={Paper} sx={{ width: "100%", height: "100%" }}>
                        <Table sx={{ width: "100%" }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={9} sx={{ textAlign: "center", fontWeight: "bold", backgroundColor:"var(--primary-clr)", color:"var(--secondary-clr)", fontSize: "2rem" }}>Report</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    report.address && <TableRow>
                                        <TableCell colSpan={4} sx={{ fontWeight: "bold", backgroundColor:"var(--primary-clr)", color:"var(--secondary-clr)" }}>Report ID</TableCell>
                                        <TableCell colSpan={5}>{tab}</TableCell>
                                    </TableRow>
                                }
                                {
                                    report.address && <TableRow>
                                        <TableCell colSpan={4} sx={{ fontWeight: "bold", backgroundColor:"var(--primary-clr)", color:"var(--secondary-clr)" }}>Address</TableCell>
                                        <TableCell colSpan={5}>{report.address}</TableCell>
                                    </TableRow>
                                }

                                {
                                    report.kpis && <TableRow>
                                        <TableCell colSpan={5} rowSpan={2} sx={{ fontWeight: "bold", backgroundColor:"var(--primary-clr)", color:"var(--secondary-clr)" }}>KPIs</TableCell>
                                        <TableCell colSpan={1} sx={{ fontWeight: "bold" }}>Equity</TableCell>
                                        <TableCell colSpan={1} sx={{ fontWeight: "bold" }}>Value After Completion</TableCell>
                                        <TableCell colSpan={1} sx={{ fontWeight: "bold" }}>Total Project Cost</TableCell>
                                        <TableCell colSpan={1} sx={{ fontWeight: "bold" }}>Construction Cost</TableCell>
                                    </TableRow>
                                }
                                {
                                    report.kpis && <TableRow>
                                        <TableCell colSpan={1}>{formatCurrency(report.kpis.equity)}</TableCell>
                                        <TableCell colSpan={1}>{formatCurrency(report.kpis.vac)}</TableCell>
                                        <TableCell colSpan={1}>{formatCurrency(report.kpis.total_project_cost)}</TableCell>
                                        <TableCell colSpan={1}>{formatCurrency(report.kpis.construction_cost)}</TableCell>
                                    </TableRow>
                                }
                                {
                                    report.architectural_plans && <TableRow>
                                        <TableCell colSpan={5} sx={{ fontWeight: "bold", backgroundColor:"var(--primary-clr)", color:"var(--secondary-clr)" }}>Architectural Plans</TableCell>
                                        <TableCell colSpan={4}>{report.architectural_plans.length}</TableCell>
                                    </TableRow>
                                }
                                {
                                    report.zoning && report.zoning.parcel && <TableRow>
                                        <TableCell rowSpan={14} colSpan={5} sx={{ fontWeight: "bold", backgroundColor:"var(--primary-clr)", color:"var(--secondary-clr)" }}>Zoning</TableCell>
                                        <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>Lot Size</TableCell>
                                        <TableCell colSpan={2}>{`${roundToTwo(report.zoning.parcel.parcel_area)} sqft`}</TableCell>
                                    </TableRow>
                                }
                                {
                                    report.zoning && report.zoning.parcel && <TableRow>
                                        <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>Min Setback Area</TableCell>
                                        <TableCell colSpan={2}>{`${roundToTwo(report.zoning.parcel.minimum_setback_area)} sqft`}</TableCell>
                                    </TableRow>
                                }
                                {
                                    report.zoning && report.zoning.parcel && <TableRow>
                                        <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>Min Open Space Area</TableCell>
                                        <TableCell colSpan={2}>{`${roundToTwo(report.zoning.parcel.mnimum_open_space_area)} sqft`}</TableCell>
                                    </TableRow>
                                }
                                {
                                    report.zoning && report.zoning.parcel && <TableRow>
                                        <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>Max Buildable Area</TableCell>
                                        <TableCell colSpan={2}>{`${roundToTwo(report.zoning.parcel.maximum_buildable_area)} sqft`}</TableCell>
                                    </TableRow>
                                }
                                {
                                    report.zoning && report.zoning.parcel && <TableRow>
                                        <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>Max Foundation Depth</TableCell>
                                        <TableCell colSpan={2}>{`${roundToTwo(report.zoning.parcel.max_foundation_depth)} ft`}</TableCell>
                                    </TableRow>
                                }
                                {
                                    report.zoning && report.zoning.parcel && <TableRow>
                                        <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>Max Foundation Width</TableCell>
                                        <TableCell colSpan={2}>{`${roundToTwo(report.zoning.parcel.max_foundation_width)} ft`}</TableCell>
                                    </TableRow>
                                }
                                {
                                    report.zoning && report.zoning.parcel && <TableRow>
                                        <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>Lot Depth</TableCell>
                                        <TableCell colSpan={2}>{`${roundToTwo(report.zoning.parcel.lot_depth)} ft`}</TableCell>
                                    </TableRow>
                                }
                                {
                                    report.zoning && report.zoning.parcel && <TableRow>
                                        <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>Lot Width</TableCell>
                                        <TableCell colSpan={2}>{`${roundToTwo(report.zoning.parcel.lot_width)} ft`}</TableCell>
                                    </TableRow>
                                }
                                {
                                    report.zoning && report.zoning.parcel && <TableRow>
                                        <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>Parcel ID</TableCell>
                                        <TableCell colSpan={2}>{report.zoning.parcel.parcel_id}</TableCell>
                                    </TableRow>
                                }
                                {
                                    report.zoning && report.zoning.parcel && <TableRow>
                                        <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>Parcel Area</TableCell>
                                        <TableCell colSpan={2}>{`${roundToTwo(report.zoning.parcel.parcel_area)} sqft`}</TableCell>
                                    </TableRow>
                                }
                                {
                                    report.zoning && report.zoning.parcel && <TableRow>
                                        <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>Owner Name</TableCell>
                                        <TableCell colSpan={2}>{report.zoning.parcel.owner_name}</TableCell>
                                    </TableRow>
                                }
                                {
                                    report.zoning && report.zoning.parcel && <TableRow>
                                        <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>Owner Address</TableCell>
                                        <TableCell colSpan={2}>{report.zoning.parcel.owner_address}</TableCell>
                                    </TableRow>
                                }
                                {
                                    report.zoning && report.zoning.parcel && <TableRow>
                                        <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>Longitude</TableCell>
                                        <TableCell colSpan={2}>{report.zoning.parcel.longitude}</TableCell>
                                    </TableRow>
                                }
                                {
                                    report.zoning && report.zoning.parcel && <TableRow>
                                        <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>Latitude</TableCell>
                                        <TableCell colSpan={2}>{report.zoning.parcel.latitude}</TableCell>
                                    </TableRow>
                                }
                                {
                                    report.zoning && report.zoning.map && <TableRow>
                                        <TableCell rowSpan={4} colSpan={5} sx={{ fontWeight: "bold", backgroundColor:"var(--primary-clr)", color:"var(--secondary-clr)" }}>Map</TableCell>
                                        <TableCell colSpan={4} sx={{ fontWeight: "bold" }}>Parcel Coordinates</TableCell>
                                    </TableRow>
                                }
                                {
                                    report.zoning && report.zoning.map && <TableRow> 
                                        <TableCell colSpan={4}>{report.zoning.map.parcel_polygon.join(' | ')}</TableCell>
                                    </TableRow>
                                }
                                {
                                    report.zoning && report.zoning.map && <TableRow>
                                        <TableCell colSpan={4} sx={{ fontWeight: "bold"}}>Setback Coordinates</TableCell>
                                    </TableRow>
                                }
                                {
                                    report.zoning && report.zoning.map && report.zoning.map.setback_polygon && <TableRow> 
                                        <TableCell colSpan={4}>{report.zoning.map.setback_polygon.join(' | ')}</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <LoadingSkeleton width={"100%"} />
            }
        </div>
    )
}

export default ReportPage