import React, { useState } from 'react'
import './CoSingleChat.css'
import { DashboardCustomize } from '@mui/icons-material'
// import TypeWriter from '../../../components/type_writer/TypeWriter'
import { useNavigate } from 'react-router-dom'
import ChatFormat from '../../../components/chatbot_msg_components/ChatFormat'
import MapWithPolygon from '../../../components/google_map/MapWithPolygon'
import { AddressOnly, CostEstimation, PlanRecommend } from './MissingInput'
import PlanNew from '../../dashboard_new/component/PlanNew'
import ChatSource from '../../../components/chatbot_msg_components/ChatSource'

function CoSingleChat({ chat, handleSubmit, preMsg, setFullChat, favoirtPlans, profile_image }) {

    const navigate = useNavigate();
    // console.log(isLastMessage);

    const hasAllRequiredInputs = (arr) =>
        arr.some(input =>
            chat.missingInputs.list_of_missing_inputs.includes(input)
        );

    // check id in the favorite list
    function isFavorite(plan_id) {
        return favoirtPlans.some(favPlan => favPlan.id === plan_id);
    }

    // select plan state
    const [selectedPlan, setSelectedPlan] = useState({});


    // const [preProfileImg, setPreProfileImg] = useState();





    return (
        <div className='co_single_chat'>
            {/* user chat */}
            {
                chat.sent_by === "User" ?
                    <div className='co_single_chat__user_chat'>
                        <div className='co_single_chat__user_profile'>

                            <img src={profile_image} alt='user profile' />
                            {/* <h5>You</h5> */}
                        </div>
                        <div className='co_single_chat__user_message'>
                            <div className='co_single_chat__answer_area'>
                                <div className='co_single_chat__answer_text'>
                                    <ChatFormat msg={chat.text} />
                                </div>
                            </div>
                            {
                                chat.missingInputs &&
                                (
                                    hasAllRequiredInputs(["building_area", "building_quality"]) ?
                                        <CostEstimation preMsg={preMsg} handleSubmit={handleSubmit} setFullChat={setFullChat} missingInputs={chat.missingInputs.list_of_missing_inputs} preAddress={chat.address} />
                                        :
                                        hasAllRequiredInputs(["min_total_area", "max_total_area", "stories"]) ?
                                            <PlanRecommend preMsg={preMsg} handleSubmit={handleSubmit} setFullChat={setFullChat} missingInputs={chat.missingInputs.list_of_missing_inputs} preAddress={chat.address} />
                                            :
                                            hasAllRequiredInputs(["address"]) ?
                                                <AddressOnly preMsg={preMsg} handleSubmit={handleSubmit} setFullChat={setFullChat} />
                                                : null
                                )
                            }
                        </div>


                    </div>
                    :
                    /* bot chat */
                    < div className='co_single_chat__bot_chat'>
                        <div className='co_single_chat__bot_profile'>
                            <img src='/images/copilot_new/iq_profile_image.svg' alt='iqland profile' />
                        </div>
                        <div className='co_single_chat__bot_message'>
                            <div className='co_single_chat__answer_area'>

                                <div className='co_single_chat__answer_text'>
                                    {/* <TypeWriter text={chat.text} isNewMessage={isLastMessage} /> */}
                                    <ChatFormat msg={chat.text} />
                                </div>
                            </div>
                            {
                                chat.map && <div className='co_single_chat__map'><MapWithPolygon polygon={chat.map} /></div>
                                // chat.map && console.log(chat.map)
                            }
                            {
                                chat.plans && chat.plans.length > 0 &&
                                <div className='copilot_new__plans_area'>
                                    <h6 className='copilot_new__title'>Plans Found: {chat.plans.length}</h6>
                                    <div className='copilot_new__plans'>
                                        {
                                            chat.plans.map((plan, index) => (
                                                <PlanNew key={index} plan={plan} fav={isFavorite(plan.id)} selectedPlan={false} selectPlan={setSelectedPlan} handleClick={() => { }} />
                                            ))
                                        }
                                    </div>
                                </div>
                            }

                            {
                                chat.sources && chat.sources.length > 0 && <ChatSource sources={chat.sources} />
                            }

                            {
                                sessionStorage.getItem("address") && sessionStorage.getItem("address") !== "NA" && <div className='co_single_chat__populate_button'>
                                    <DashboardCustomize style={{ fontSize: "16px" }} />
                                    <h6 onClick={() => navigate("/dashboard")}>Populate Dashboard</h6>
                                </div>
                            }
                        </div>
                    </div>
            }

        </div >
    )
}

export default CoSingleChat