import { Alert, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, Snackbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom'
import { resetPassword } from '../../components/user_service/UserService';
function ResetPassword() {
    const [toastShow, setToastShow] = useState(false);
    const [toastText, setToastText] = useState('');
    const [toastStatus, setToastStatus] = useState('success');

    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };


    const [firstSegment, setFirstSegment] = useState('');
    const [secondSegment, setSecondSegment] = useState('');

    const handleSubmit = async (password, uidb64, token) => {
        try {
            const response = await resetPassword(password, uidb64, token)
            // console.log(response);
            setInterval(() => {
                setToastText("Password Changed")
                setToastStatus("success")
                setToastShow(true)
                window.location.href = '/login'
            }, 2000);
        } catch (error) {

            // console.log(error.message);
            setToastText("Invalid token")
            setToastStatus("warning")
            setToastShow(true)
        }

    }

    const formik = useFormik({
        initialValues: {
            password: '',
            confirm_password: ''
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .min(6, 'must be at least 6 characters')
                .matches(/[A-Z]/, 'must contain at least one uppercase letter')
                .matches(/[a-z]/, 'must contain at least one lowercase letter')
                .matches(/[0-9]/, 'must contain at least one number')
                .matches(/[!@#$%^&*(),.?":{}|<>]/, 'must contain at least one symbol')
                .required('Password is required'),
            confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
        }),
        onSubmit: (values) => {
            handleSubmit(values.password, secondSegment, firstSegment);
        },
    });


    useEffect(() => {
        // Get the current pathname
        const pathname = window.location.pathname;

        // Split the pathname to get the segments
        const segments = pathname.split('/');


        if (segments.length === 5) {

            // Retrieve the required segments
            const segment1 = segments[2];
            const segment2 = segments[3];

            // Set the state variables
            setFirstSegment(segment1);
            //token
            setSecondSegment(segment2)
        }
        else{
            navigate('/error')
        }
    }, []);


    return (
        <div className='new_login_page'>
            <div className='new_login__image_side'>
                <img src="/images/login/login_page_img.jpg" alt="login" />
            </div>

            <div className='new_login__login_form_area'>

                <form onSubmit={formik.handleSubmit} className='new_login__login_form'>

                    <div className='new_login__form_title_area'>
                        <Typography className='new_login__form_title'>Reset Password</Typography>
                    </div>


                    <FormControl sx={{ width: "100%" }} variant="outlined" error={formik.touched.password && Boolean(formik.errors.password)}>
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={togglePasswordVisibility}
                                    >
                                        {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.password && formik.errors.password ? (
                            <FormHelperText>{formik.errors.password}</FormHelperText>
                        ) : null}
                    </FormControl>

                    <FormControl sx={{ width: "100%" }} variant="outlined" error={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}>
                        <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-confirm-password"
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={togglePasswordVisibility}
                                    >
                                        {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Confirm Password"
                            name="confirm_password"
                            value={formik.values.confirm_password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.confirm_password && formik.errors.confirm_password ? (
                            <FormHelperText>{formik.errors.confirm_password}</FormHelperText>
                        ) : null}
                    </FormControl>
                    <div className='new_login__buttons'>
                        <div className='new_login__button_area'>
                            <input type="submit" value="Submit" className='iq_primary_btn' />
                        </div>
                    </div>
                </form>


            </div>
            <Snackbar
                open={toastShow}
                autoHideDuration={6000}
                onClose={() => setToastShow(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setToastShow(false)}
                    severity={toastStatus}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {toastText}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default ResetPassword;
