import React, { useState } from 'react';
import './SecuritySetting.css';
import UserNav from '../../components/user_nav/UserNav';
import SettingSideBar from './SettingSideBar';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { changePassword } from '../../components/user_service/UserService';
import { Alert, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, Snackbar, Typography } from '@mui/material';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import SettingMobileNav from './SettingMobileNav';

function SecuritySetting() {

    const [mobileSideBar, setMobileSidebar] = useState(false);

    const handleMobileSidebar = () => {
        setMobileSidebar(!mobileSideBar);
    };


    const [text, setText] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    const formikPassword = useFormik({
        initialValues: {
            password: '',
            confirm_password: ''
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .min(6, 'must be at least 6 characters')
                .matches(/[A-Z]/, 'must contain at least one uppercase letter')
                .matches(/[a-z]/, 'must contain at least one lowercase letter')
                .matches(/[0-9]/, 'must contain at least one number')
                .matches(/[!@#$%^&*(),.?":{}|<>]/, 'must contain at least one symbol')
                .required('New Password is required'),
            confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
        }),
        onSubmit: (values, { setSubmitting }) => {
            try {
                changePassword(values.password);
                setSnackbarMessage('Password changed successfully');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
            } catch (error) {
                console.log(error);
                setSnackbarMessage('Error changing password');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            } finally {
                setSubmitting(false);
            }
        },
        validateOnBlur: true,
        validateOnChange: true
    });

    return (
        <div className='security_setting'>
            <UserNav handleBurgerClick={handleMobileSidebar}/>
            <div className='security_setting__container'>
                <SettingSideBar tab={"4"} />
                <div className='security_setting__main'>
                    <div className='security_setting__part'>
                        <h5>Security & Password</h5>
                        <div className='security_setting__inputs'>
                            <div className='security_setting__inputs_sets'>
                                <FormControl sx={{ width: "100%" }} variant="outlined" error={formikPassword.touched.password && Boolean(formikPassword.errors.password)}>
                                    <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    sx={{ maxWidth: "2.5rem" }}
                                                    aria-label="toggle password visibility"
                                                    onClick={togglePasswordVisibility}
                                                >
                                                    {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="New Password"
                                        name="password"
                                        value={formikPassword.values.password}
                                        onChange={formikPassword.handleChange}
                                    />
                                    {formikPassword.touched.password && formikPassword.errors.password ? (
                                        <FormHelperText>{formikPassword.errors.password}</FormHelperText>
                                    ) : null}
                                </FormControl>
                                <FormControl sx={{ width: "100%" }} variant="outlined" error={formikPassword.touched.confirm_password && Boolean(formikPassword.errors.confirm_password)}>
                                    <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-confirm-password"
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    sx={{ maxWidth: "2.5rem" }}
                                                    aria-label="toggle password visibility"
                                                    onClick={togglePasswordVisibility}
                                                >
                                                    {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Confirm Password"
                                        name="confirm_password"
                                        value={formikPassword.values.confirm_password}
                                        onChange={formikPassword.handleChange}
                                    />
                                    {formikPassword.touched.confirm_password && formikPassword.errors.confirm_password ? (
                                        <FormHelperText>{formikPassword.errors.confirm_password}</FormHelperText>
                                    ) : null}
                                </FormControl>
                            </div>
                            {/* <div className='security_setting__inputs_sets'>
                                <IQTextboxLabel label='Notification Preferences' value={text} placeholder={"Not Available"} disabled={true} />
                                <IQTextboxLabel label='Email Subscription Setting' disabled={true} value={text} setText={() => setText()} placeholder={"Not Available"} />
                            </div> */}
                        </div>
                        <button className='security_setting__btn' onClick={formikPassword.handleSubmit}>Update</button>
                    </div>

                    {/* <div className='security_setting__part'>
                        <h5>2 Factor Authentication </h5>
                        <div className='security_setting__inputs'>
                            <div className='security_setting__inputs_sets'>
                                <IQTextboxLabel label='Membership Date' value="Not Available" disabled={true} />
                                <IQTextboxLabel label='Preffered Home Styles Select' value="Not Available" disabled={true} />
                            </div>
                            <div className='security_setting__inputs_sets'>
                                <IQTextboxLabel label='Security Questions ' value="Not Available" disabled={true} />
                            </div>
                        </div>
                        <button className='security_setting__btn' disabled>Update</button>
                    </div> */}
                </div>
            </div>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>


            {
                mobileSideBar && <SettingMobileNav tab={"4"} handleMobileSidebar={handleMobileSidebar} />
            }
        </div>
    );
}

export default SecuritySetting;
