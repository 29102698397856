import { Alert, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, Snackbar, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { EmailOutlined, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom'
import { registerWithEmail } from '../../components/auth/AuthService';

function NewRegister() {
    const [toastShow, setToastShow] = useState(false);
    const [toastText, setToastText] = useState('');
    const [toastStatus, setToastStatus] = useState('success');

    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    const handleSubmit = async (firstname, lastname, email, password) => {
        try {
            const response = await registerWithEmail(firstname, lastname, email, password);
            // console.log(response);
            navigate('/verify_email', { state: { email: email } })
        } catch (error) {

            // console.log(error.message);
            setToastText(error.message)
            setToastStatus("warning")
            setToastShow(true)
        }

    }

    const formik = useFormik({
        initialValues: {
            first_name: '',
            email: '',
            password: '',
            confirm_password: ''
        },
        validationSchema: Yup.object({
            first_name: Yup.string().required('First Name is required'),
            email: Yup.string().email('Invalid email').required('Email is required'),
            password: Yup.string()
                .min(6, 'must be at least 6 characters')
                .matches(/[A-Z]/, 'must contain at least one uppercase letter')
                .matches(/[a-z]/, 'must contain at least one lowercase letter')
                .matches(/[0-9]/, 'must contain at least one number')
                .matches(/[!@#$%^&*(),.?":{}|<>]/, 'must contain at least one symbol')
                .required('Password is required'),
            confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
        }),
        onSubmit: (values) => {
            handleSubmit(values.first_name, "NA", values.email, values.password)
        },
    });


    return (
        <div className='new_login_page'>
            <div className='new_login__image_side'>
                <img src="/images/login/login_page_img.jpg" alt="login" />
            </div>

            <div className='new_login__login_form_area'>

                <form onSubmit={formik.handleSubmit} className='new_login__login_form'>

                    <img className='logo' src='/images/logo.svg' alt='logo' />
                    <div className='new_login__form_title_area'>
                        <Typography className='new_login__form_title'>Register</Typography>
                    </div>
                    <FormControl sx={{ width: "100%" }} variant="outlined" error={formik.touched.first_name && Boolean(formik.errors.first_name)}>
                        <InputLabel htmlFor="outlined-adornment-first">First Name</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-first"
                            type="text"
                            label="First Name"
                            name="first_name"
                            value={formik.values.first_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.first_name && formik.errors.first_name ? (
                            <FormHelperText>{formik.errors.first_name}</FormHelperText>
                        ) : null}
                    </FormControl>
                    {/* <FormControl sx={{ width: "100%" }} variant="outlined" error={formik.touched.last_name && Boolean(formik.errors.last_name)}>
                        <InputLabel htmlFor="outlined-adornment-last">Last Name</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-last"
                            type="text"
                            label="Last Name"
                            name="last_name"
                            value={formik.values.last_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.last_name && formik.errors.last_name ? (
                            <FormHelperText>{formik.errors.last_name}</FormHelperText>
                        ) : null}
                    </FormControl> */}

                    <FormControl sx={{ width: "100%" }} variant="outlined" error={formik.touched.email && Boolean(formik.errors.email)}>
                        <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-email"
                            type="email"
                            endAdornment={
                                <InputAdornment position="end">
                                    <EmailOutlined />
                                </InputAdornment>
                            }
                            label="Email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />

                        {formik.touched.email && formik.errors.email ? (
                            <FormHelperText>{formik.errors.email}</FormHelperText>
                        ) : null}
                    </FormControl>

                    <FormControl sx={{ width: "100%" }} variant="outlined" error={formik.touched.password && Boolean(formik.errors.password)}>
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={togglePasswordVisibility}
                                    >
                                        {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.password && formik.errors.password ? (
                            <FormHelperText>{formik.errors.password}</FormHelperText>
                        ) : null}
                    </FormControl>

                    <FormControl sx={{ width: "100%" }} variant="outlined" error={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}>
                        <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-confirm-password"
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={togglePasswordVisibility}
                                    >
                                        {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Confirm Password"
                            name="confirm_password"
                            value={formik.values.confirm_password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.confirm_password && formik.errors.confirm_password ? (
                            <FormHelperText>{formik.errors.confirm_password}</FormHelperText>
                        ) : null}
                    </FormControl>
                    <div className='new_login__buttons'>
                        <div className='new_login__button_area'>
                            <input type="submit" value="Register" className='iq_primary_btn' />
                        </div>
                        <div className='new_login__have_account'>
                            <Typography className='new_login__have_account_text'>Already have account?<span onClick={() => navigate("/login")}> Sign in</span></Typography>
                        </div>
                        {/* <div className='new_login__or_area'>
                            <img src="/images/login/line_img.svg" alt="login line" />
                            Or
                            <img src="/images/login/line_img.svg" alt="login line" />
                        </div>

                        <div className='social_media_button'>
                            <img src="/images/login/google_icon.svg" alt="login facebook" />
                            Login with Google
                        </div>
                        <div className='social_media_button'>
                            <img src="/images/login/facebook_icon.svg" alt="login facebook" />
                            Login with Facebook
                        </div> */}
                    </div>
                </form>


            </div>
            <Snackbar
                open={toastShow}
                autoHideDuration={6000}
                onClose={() => setToastShow(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setToastShow(false)}
                    severity={toastStatus}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {toastText}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default NewRegister
