export const faqData = [
    {
        "title": "General Questions",
        "values": [
            {
                "q": "What is IQLAND?",
                "a": "IQLAND is a webapp that provides land intelligence and development solutions for real estate developers. We offer a dashboard and a copilot chatbot to help streamline your activity and provide insights on parcels, zoning information, and cost and profitability estimations."
            },
            {
                "q": "What areas do you currently serve?",
                "a": "We currently deploy in Tulsa and OKC, with ongoing expansions to other areas. You can get details of the deployment status on this page."
            },
            {
                "q": "What is the purpose of the copilot chatbot?",
                "a": "The copilot chatbot is designed to assist you with questions about parcels, zoning regulations, and other land development-related topics."
            }
        ]
    },
    {
        "title": "Pricing and Plans",
        "values": [
            {
                "q": "How much does IQLAND cost?",
                "a": "We offer a 7-day free trial, after which you can subscribe monthly or yearly. You can save up to 20% thanks to yearly subscriptions. Details of cost are accessible on the [pricing page](/pricing)."
            },
            {
                "q": "What is included in the plan?",
                "a": "Our plan includes access to the dashboard, copilot chatbot, and their features designed to assist you on optimizing your real estate development activities. Details are available on the [pricing page](/pricing). Premium features such as lot subdivision or automatic ROI optimization are coming soon."
            },
            {
                "q": "How can I sign up?",
                "a": "You can join IQLAND through the [sign up page](/register) to create a free account. Once created, you need to subscribe to an active plan to get access to the features offered by IQLAND. Free cancellation is possible anytime during the 7-day free trial period."
            },
            {
                "q": "Can I cancel my subscription at any time?",
                "a": "Yes, you can cancel your subscription at any time prior to the upcoming billing period. Features will stay active until then after cancellation is confirmed."
            }
        ]
    },
    {
        "title": "Login and Security",
        "values": [
            {
                "q": "How do I log in to IQLAND?",
                "a": "To log in to IQLAND, simply enter your email address and password in the [login page](/login), and complete the 2-factor authentication process via email. You also have the option to remember your device for future logins."
            },
            {
                "q": "What is 2-factor authentication, and why is it required?",
                "a": "2-factor authentication is an additional security measure that requires you to verify your identity through a second method, in addition to your password. This helps to protect your account and ensure that only authorized users can access your information."
            },
            {
                "q": "Are there other 2-factor authentication methods available besides email?",
                "a": "Yes, we are working on implementing additional 2-factor authentication methods, which will be available soon."
            }
        ]
    },
    {
        "title": "Features",
        "values": [
            {
                "q": "What information can I access through the dashboard?",
                "a": "The dashboard provides insights on parcels, zoning information, and cost and profitability estimations for a given address."
            },
            {
                "q": "How do I use the copilot chatbot?",
                "a": "You can start conversations using the proposed pre-prompts or simply type your question into the chatbot, and our AI-powered assistant will provide you with an answer. The copilot is tailored to assist you on many topics related to land development."
            },
            {
                "q": "Are there any limitations to the features and capabilities of IQLAND?",
                "a": "Some premium features are currently in development and will be available soon. In the meantime, our standard plan includes access to all current capabilities."
            }
        ]
    },
    {
        "title": "Support and Contact",
        "values": [
            {
                "q": "How do I contact IQLAND support?",
                "a": "You can contact our support team through our website or by emailing support@iqland.com."
            },
            {
                "q": "What hours is support available?",
                "a": "Our support team is available during business hours, Monday-Friday, 9am-5pm CST."
            }
        ]
    }
]
