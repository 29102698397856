import React from 'react'
import './LanReviews.css'

function LanReviews({comments, cusName, job, image}) {
    return (
        <div className='lan_review_card'>
            <div className='lan_review_card__stars'><img src='/images/lan_home/stars.png' alt='stars' /></div>
            <div className='lan_review_card__comments'>{comments}</div>
            <div className='lan_review_card__profile'>
                <img src={`/images/lan_home/${image}`} alt='reviewer' style={{width:"3rem", height:"3rem", objectFit:"cover"}} />
                <div className='lan_review_card__profile_details'>
                    <div className='lan_review_card__profile_name'>{cusName}</div>
                    <div className='lan_review_card__profile_occ'>{job}</div>
                </div>
            </div>
        </div>
    )
}

export default LanReviews