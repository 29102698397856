import React from 'react'
import FaqComp from './components/faq_comp/FaqComp'
import { faqData } from './data/FaqData'
import NavBar from '../../components/navbar/NavBar'
import Footer from '../../components/footer/Footer'

function FaqPage() {
    return (
        <>
            <NavBar/>
            <div className='lan_faqs'>
                <div className='lan_title' style={{ color: "var(--primary-clr)" }}>FAQs</div>
                <FaqComp data={faqData} />
            </div>
            <Footer/>
        </>
    )
}

export default FaqPage