import React, { useEffect, useState } from 'react'
import './MetricCard.css'
import { Tooltip } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import { LoadingSkeletonText } from '../../../components/skeleton_loading/SkeletonLoading'

function MetricCard({ icon, icon_back, icon_alt, title, tt_text, amount, increase_amount, loading, isNegative }) {
    
    const [backColor, setBackColor] = useState({
        backgroundColor: "white",
        color: "#2E2C34"
    });
    useEffect(() => {
        if(isNegative){
            setBackColor({
                backgroundColor: "rgb(255, 46, 46)",
                color: "#fff"
            })
        }
        else if(title=== "Equity"){
            setBackColor({
                backgroundColor: "var(--primary-clr)",
                color: "#fff"
            })
        }
    }, [amount])


    return (
        <div className='metric_card' style={backColor}>
            <div className='metric_card__text_area'>
                <div className='metric_card__icon' style={{ backgroundColor: icon_back}}>
                    <img src={`/images/dashboard_new/${icon}.svg`} alt={icon_alt} style={{ borderRadius: "50%" }} />
                </div>
                <h5 style={{color: title === "Equity"?"#fff": "#626262"}}>{title}</h5>
                <Tooltip title={tt_text} arrow>
                    <InfoOutlined sx={{width:"1rem", height:"1rem"}}/>
                </Tooltip>
            </div>
            <div className='metric_card__amount'>
                {loading ? <LoadingSkeletonText/>: amount}
            </div>
            <div className='metric_card__increase'>
                <div className='metric_card__increase_per'>
                    {/* <div><img src={`/images/dashboard_new/${increased ? "increase" : "decrease"}.svg`} alt="graph" /></div> */}
                    {/* <div className='percentage_increase'>{increase_per}</div> */}
                </div>
                <div className='metric_card__increase_amount'>
                    {increase_amount && increase_amount}
                </div>
            </div>
        </div>
    )
}

export default MetricCard