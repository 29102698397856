import React from 'react';
import './Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faX } from '@fortawesome/free-solid-svg-icons';

function Footer() {
  return (
    <footer className='footer-section'>

      <div className='footer__top'>
        <div style={{ maxWidth: "10rem", flexShrink: "0" }}><img src='/images/logo.png' alt='logo' /></div>
        <div className='footer__link_list'>
          <div>
            <ul className='footer__links'>
              <li className='title'>About Us</li>
              <li><a href='/pricing' target='_blank' rel='noopener noreferrer' style={{textDecoration:"none", color:"black"}}>Pricing</a></li>
              {/* <li>Enterprise Solutions</li>
              <li>Affiliate Program</li> */}
            </ul>
          </div>
          <div>
            <ul className='footer__links'>
              <li className='title'>Learn</li>
              <li><a href='/faqs' target='_blank' rel='noopener noreferrer' style={{textDecoration:"none", color:"black"}}>FAQs</a></li>
              {/* <li>Blogs</li> */}
            </ul>
          </div>
          <div>
            <ul className='footer__links'>
              <li className='title'>Products</li>
              <li><a href='/products/copilot' target='_blank' rel='noopener noreferrer' style={{textDecoration:"none", color:"black"}}>Copilot</a></li>
              <li><a href='/products/dashboard' target='_blank' rel='noopener noreferrer' style={{textDecoration:"none", color:"black"}}>Dashboard</a></li>
            </ul>
          </div>
          <div>
            <ul className='footer__links'>
              <li className='title'>Customer Support</li>
              <li><a style={{textDecoration:"none", color:"black"}} href="mailto:support@iqland.ai"> support@iqland.ai</a></li>
              <li><a href='/contact' target='_blank' rel='noopener noreferrer' style={{textDecoration:"none", color:"black"}}>Contact</a></li>
            </ul>
          </div>
        </div>

      </div>

      <div className='footer__bottom'>
        <div className='footer__copyright_text'>
          © Copyright 2024. All rights reserved.
        </div>

        <ul className='footer__social_links'>
          <li><FontAwesomeIcon icon={faX} /> </li>
          <li><FontAwesomeIcon icon={faFacebook} /> </li>
          <li><FontAwesomeIcon icon={faLinkedin} /> </li>
          <li><FontAwesomeIcon icon={faInstagram} /> </li>
          <li><FontAwesomeIcon icon={faTiktok} /> </li>
        </ul>
        
        {/* <div className='footer__site_map'>
          Site Map
        </div> */}
      </div>

    </footer>
  )
}

export default Footer