import React from 'react'


function ReportAction({ items, report_id}) {
    return (
        <ul className='IQList'>
            {
                items.map((i, index) => (

                    <li onClick={i.action ? () => i.action(report_id) : null} key={index}>
                        {i.icon && <img src={`/images/co_reports/${i.icon}`} alt='a' />}
                        {i.text}
                    </li>

                ))
            }
        </ul>
    )
}

export default ReportAction