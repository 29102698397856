import { ExpandMoreOutlined } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import React from 'react'
import ChatFormat from '../../../../components/chatbot_msg_components/ChatFormat'

function FaqComp({ data }) {
    return (
        data.map((item, index) => (
            <Accordion key={index} sx={{width:"100%"}} className='lan_dashboard_plan_left_acc'>
                <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <div className='acc_title_area'>
                        <div className='lan_sub_title_acc'>{item.title}</div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    {
                        item.values.map((value, index) => (
                            <Accordion key={index} sx={{width:"100%"}} className='lan_dashboard_plan_left_acc'>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreOutlined />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <div className='acc_title_area'>
                                        <div className='' style={{fontSize:"1rem"}}><ChatFormat msg={value.q}/></div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className='lan_normal_text' style={{fontSize:"1rem"}}>
                                        <ChatFormat msg={value.a}/>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    }
                </AccordionDetails>
            </Accordion>
        ))
    )
}

export default FaqComp