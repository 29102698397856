import { createContext, useEffect, useState } from "react";
import { checkSubscriptionNavigationStatus } from "./AuthService";

// {
//     "has_active_subscription": false,
//     "message": "You don't have an active subscription"
// }

export const SubsContext = createContext();

export const SubsProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    checkSubscriptionNavigationStatus()
      .then(response => {
        setIsActive(response.has_active_subscription)
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);


  return (
    <SubsContext.Provider value={{loading, isActive }}>
      {children}
    </SubsContext.Provider>
  );
};